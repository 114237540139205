import { v4 as uuidv4 } from 'uuid';

const config = {
  host: process.env.REACT_APP_API_HOST || 'https://oop8dv9jfj.execute-api.us-east-1.amazonaws.com',
};

class UserService {
  static generatePublicLink(email) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/generate-public-link`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              success: true,
              ...data
            });
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Public link generation failed'));
          } catch (error) {
            reject(new Error('Public link generation failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to generate public link'));
      };

      const data = JSON.stringify({
        email
      });

      xhr.send(data);
    });
  }

  static saveUser(user) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/user`, true);
      xhr.withCredentials = true; // Include cookies
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              success: true,
              ...data
            });
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Registration failed'));
          } catch (error) {
            reject(new Error('Registration failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to register'));
      };

      const data = JSON.stringify({
        email: user.email,
        full_name: user.full_name,
        phone_number: user.phone_number,
        state_region: user.state_region,
        address: user.address,
        country: user.country,
        city: user.city,
        zip_code: user.zip_code,
        profile_picture: user.profile_picture,
        is_active: user.is_active,
        role: user.role,
        peers: user.peers
      });

      xhr.send(data);
    });
  }

  static addUser(email) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/add-user`, true);
      xhr.withCredentials = true; // Include cookies
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              success: true,
              ...data
            });
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Registration failed'));
          } catch (error) {
            reject(new Error('Registration failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to register'));
      };

      const data = JSON.stringify({
        email
      });

      xhr.send(data);
    });
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${config.host}/user`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              user: data.user
            });
          } catch (error) {
            reject(new Error('Failed to parse response data'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Authentication failed'));
          } catch (error) {
            reject(new Error('Authentication failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to authenticate'));
      };

      xhr.send();
    });
  }

  static getUsers() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${config.host}/users`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              users: data.users
            });
          } catch (error) {
            reject(new Error('Failed to parse response data'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Authentication failed'));
          } catch (error) {
            reject(new Error('Authentication failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to authenticate'));
      };

      xhr.send();
    });
  }
}

export default UserService;