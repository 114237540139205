const ISO4To10FrameworkMapping = {
  "Clause 4.2": {
      "title": "Understanding the needs and expectations of interested parties",
      "description": "Did the organization determine interested parties?\nDoes the list of all of interested parties’ requirements exist?",
      "testSteps": "Requirement: Did the organization determine interested parties?\nStep 1: Obtain the documented list of interested parties.\nStep 2: Verify the process used to identify and document these parties.\nStep 3: Confirm that the documentation includes relevant internal and external parties.\n\nRequirement: Does the list of all interested parties’ requirements exist?\nStep 1: Request evidence of requirements (e.g., stakeholder expectations or agreements).\nStep 2: Validate alignment between documented requirements and organizational objectives.\nStep 3: Assess whether these requirements influence the ISMS policy and risk treatment plans.",
      "frequency": "Annually"
  },
  "Clause 4.3": {
      "title": "Determining the scope of the ISMS",
      "description": "Is the scope documented with clearly defined boundaries and interfaces?",
      "testSteps": "Step 1: Review the scope document for completeness and clarity.\nStep 2: Confirm that the scope considers internal and external issues, as well as identified interested parties.\nStep 3: Verify that the scope appropriately reflects ISMS objectives and covers all critical processes.",
      "frequency": "Annually"
  },
  "Clause 5.1": {
      "title": "Leadership and commitment",
      "description": "Are the general ISMS objectives compatible with the strategic direction?\nDoes management ensure that ISMS achieves its objectives?",
      "testSteps": "Requirement: Are the general ISMS objectives compatible with the strategic direction?\nStep 1: Request ISMS objectives and compare them with the organization's strategic goals.\nStep 2: Confirm that objectives are measurable and aligned with organizational policies.\nStep 3: Interview management to validate their understanding and commitment.\n\nRequirement: Does management ensure that ISMS achieves its objectives?\nStep 1: Obtain evidence of management involvement, such as meeting minutes or oversight actions.\nStep 2: Review implementation progress and performance metrics tied to ISMS objectives.\nStep 3: Verify corrective actions for missed targets and evidence of continuous improvement.",
      "frequency": "Annually"
  },
  "Clause 5.2": {
      "title": "Information security policy",
      "description": "Does an Information Security Policy exist with objectives or a framework for setting objectives?\nIs the Information Security Policy communicated within the company?",
      "testSteps": "Requirement: Does the Information Security Policy exist with objectives or framework for setting objectives?\nStep 1: Review the documented Information Security Policy for compliance with ISO 27001 requirements.\nStep 2: Ensure the policy specifies a framework for defining and achieving ISMS objectives.\nStep 3: Confirm periodic review and approval by top management.\n\nRequirement: Is the Information Security Policy communicated within the company?\nStep 1: Verify communication records, such as training logs or newsletters.\nStep 2: Interview employees to assess their awareness of the policy.\nStep 3: Confirm accessibility of the policy via intranet, handbook, or noticeboards.",
      "frequency": "Annually"
  },
  "Clause 5.3": {
      "title": "Roles and responsibilities",
      "description": "Are roles and responsibilities for information security assigned and communicated?",
      "testSteps": "Step 1: Review documentation defining roles and responsibilities (e.g., RACI matrices or organizational charts).\nStep 2: Interview staff to confirm awareness of their security-related duties.\nStep 3: Verify records of communication, such as meeting minutes or onboarding documents.",
      "frequency": "Annually"
  },
  "Clause 6.1.2": {
      "title": "Risk assessment",
      "description": "Is the risk assessment process documented, including the risk acceptance criteria and criteria for risk assessment?\nAre the risks identified, their owners, likelihood, consequences, and the level of risk; are these results documented?",
      "testSteps": "Step 1: Obtain and review the risk assessment methodology.\nStep 2: Confirm that the methodology includes risk acceptance criteria and detailed steps for assessment.\nStep 3: Validate implementation by examining completed risk assessments and associated documentation.",
      "frequency": "Annually"
  },
  "Clause 6.1.3": {
      "title": "Risk treatment plan implementation",
      "description": "Is the risk treatment process documented, including the risk treatment options?\nAre all the unacceptable risks treated using the options and controls from Annex A; are these results documented?\nIs a Statement of Applicability produced with justifications and status for each control?\nDoes a Risk treatment plan exist, approved by risk owners?",
      "testSteps": "Requirement: Is the risk treatment process documented, including the risk treatment options?\nStep 1: Review the documented risk treatment process for completeness and clarity.\nStep 2: Verify that the process includes identification of risk treatment options (e.g., mitigate, transfer, accept, or avoid).\nStep 3: Confirm alignment of the risk treatment process with the organization's risk assessment results.\n\nRequirement: Are all unacceptable risks treated using the options and controls from Annex A, and are these results documented?\nStep 1: Request evidence of risk treatment implementation, such as logs or control application records.\nStep 2: Confirm that unacceptable risks are addressed using appropriate Annex A controls.\nStep 3: Validate that treatment results are documented and periodically reviewed.\n\nRequirement: Is a Statement of Applicability (SoA) produced with justifications and status for each control?\nStep 1: Review the SoA to ensure all applicable controls are listed with justifications.\nStep 2: Verify the implementation status of each control (e.g., implemented, planned, not applicable).\nStep 3: Confirm management approval of the SoA.\n\nRequirement: Does a Risk Treatment Plan exist, approved by risk owners?\nStep 1: Request and review the Risk Treatment Plan for clarity and comprehensiveness.\nStep 2: Verify that the plan is formally approved by designated risk owners.\nStep 3: Ensure the plan includes responsibilities, resources, and deadlines.",
      "frequency": "Annually"
  },
  "Clause 6.2": {
      "title": "Information security objectives",
      "description": "Does Risk treatment plan define who is responsible for implementation of which control, with which resources, what are the deadlines, and what is the evaluation method?",
      "testSteps": "Step 1: Review the Risk Treatment Plan for clearly assigned responsibilities and resource allocation.\nStep 2: Confirm that deadlines are realistic and evaluation methods are defined.\nStep 3: Verify that responsibilities align with organizational roles and resource availability.",
      "frequency": "Annually"
  },
  "Clause 7.1": {
      "title": "Resources",
      "description": "Are adequate resources provided for all the elements of ISMS?",
      "testSteps": "Step 1: Review the organization's resource allocation plan for ISMS activities.\nStep 2: Interview key stakeholders to confirm that resources are sufficient for their tasks.\nStep 3: Cross-check with ISMS budgetary documents and project plans.",
      "frequency": "Annually"
  },
  "Clause 7.2": {
      "title": "Competence",
      "description": "Are required competences defined, trainings performed, and records of competences maintained?",
      "testSteps": "Step 1: Review the competency framework and role-based training requirements.\nStep 2: Verify evidence of training sessions, including attendance records and evaluations.\nStep 3: Ensure records of competence (certifications, assessments) are securely maintained.",
      "frequency": "Annually"
  },
  "Clause 7.3": {
      "title": "",
      "description": "Is the personnel aware of Information security policy, of their role, and consequences of not complying with the rules?",
      "testSteps": "Step 1: Interview employees to assess awareness of the Information Security Policy and their roles.\nStep 2: Confirm communication and training logs to ensure widespread policy dissemination.\nStep 3: Review disciplinary records for evidence of policy enforcement.",
      "frequency": "Annually"
  },
  "Clause 7.4": {
      "title": "Communication",
      "description": "Does the process for communication related to information security exist, including the responsibilities and what to communicate?",
      "testSteps": "Step 1: Review the documented communication process and verify defined responsibilities.\nStep 2: Confirm periodic communication of ISMS updates, threats, or policy changes.\nStep 3: Evaluate the effectiveness of communication via surveys or feedback.",
      "frequency": "Annually"
  },
  "Clause 7.5": {
      "title": "",
      "description": "Does the process for managing documents and records exist, including who reviews and approves documents, where and how they are published, stored and protected?\nAre documents of external origin controlled?",
      "testSteps": "Step 1: Review the documented process for managing ISMS documents and records.\nStep 2: Verify the existence of version control, approval workflows, and secure storage.\nStep 3: Confirm that external documents are identified and controlled.",
      "frequency": "Annually"
  },
  "Clause 8.1": {
      "title": "Operational planning and control",
      "description": "Are outsourced processes identified and controlled?",
      "testSteps": "Step 1: Review the list of outsourced processes and verify inclusion in the ISMS scope.\nStep 2: Assess the adequacy of contracts, agreements, and SLAs for controlling outsourced processes.\nStep 3: Confirm periodic monitoring and review of outsourced activities.",
      "frequency": "Annually"
  },
  "Clause 8.2": {
      "title": "Risk assessment plan implementation",
      "description": "Are the risks identified, their owners, likelihood, consequences, and the level of risk; are these results documented?",
      "testSteps": "Step 1: Review completed risk assessments for completeness.\nStep 2: Verify that each risk has an assigned owner and a documented likelihood and impact.\nStep 3: Validate the risk prioritization and mitigation planning process.",
      "frequency": "Annually"
  },
  "Clause 8.3": {
      "title": "Risk treatment plan implementation",
      "description": "Is the risk treatment process documented, including the risk treatment options?\nAre all the unacceptable risks treated using the options and controls from Annex A; are these results documented?\nIs a Statement of Applicability produced with justifications and status for each control?\nDoes a Risk treatment plan exist, approved by risk owners?",
      "testSteps": "Requirement: Is the risk treatment process documented, including the risk treatment options?\nStep 1: Review the documented risk treatment process for completeness and clarity.\nStep 2: Verify that the process includes identification of risk treatment options (e.g., mitigate, transfer, accept, or avoid).\nStep 3: Confirm alignment of the risk treatment process with the organization's risk assessment results.\n\nRequirement: Are all unacceptable risks treated using the options and controls from Annex A, and are these results documented?\nStep 1: Request evidence of risk treatment implementation, such as logs or control application records.\nStep 2: Confirm that unacceptable risks are addressed using appropriate Annex A controls.\nStep 3: Validate that treatment results are documented and periodically reviewed.\n\nRequirement: Is a Statement of Applicability (SoA) produced with justifications and status for each control?\nStep 1: Review the SoA to ensure all applicable controls are listed with justifications.\nStep 2: Verify the implementation status of each control (e.g., implemented, planned, not applicable).\nStep 3: Confirm management approval of the SoA.\n\nRequirement: Does a Risk Treatment Plan exist, approved by risk owners?\nStep 1: Request and review the Risk Treatment Plan for clarity and comprehensiveness.\nStep 2: Verify that the plan is formally approved by designated risk owners.\nStep 3: Ensure the plan includes responsibilities, resources, and deadlines.",
      "frequency": "Annually"
  },
  "Clause 9.1": {
      "title": "",
      "description": "Is it defined what needs to be measured, by which method, who is responsible, who will analyze and evaluate the results?\nAre the results of measurement documented and reported to responsible persons?",
      "testSteps": "Requirement: Is it defined what needs to be measured, by which method, who is responsible, and who will analyze and evaluate the results?\nStep 1: Review the measurement framework for ISMS performance.\nStep 2: Verify that methods, responsibilities, and timelines for measurement are clearly defined.\nStep 3: Confirm the accuracy and timeliness of reporting results.\n\nRequirement: Are the results of measurement documented and reported to responsible persons?\nStep 1: Obtain measurement reports and confirm their consistency with ISMS goals.\nStep 2: Verify that reports are shared with designated responsible parties.\nStep 3: Assess follow-up actions based on measurement results.",
      "frequency": "Annually"
  },
  "Clause 9.2": {
      "title": "Internal audits",
      "description": "Does an audit program exist that defines the timing, responsibilities, reporting, audit criteria and scope?\nAre internal audits performed according to audit program, results reported through the Internal audit report and relevant corrective actions raised?",
      "testSteps": "Requirement: Does an audit program exist that defines the timing, responsibilities, reporting, audit criteria, and scope?\nStep 1: Review the documented internal audit program for completeness (timing, responsibilities, criteria, scope).\nStep 2: Verify that the audit program is approved by management.\nStep 3: Confirm alignment of the audit scope with the ISMS objectives and scope.\n\nRequirement: Are internal audits performed according to the audit program, with results reported through the Internal Audit Report and relevant corrective actions raised?\nStep 1: Request evidence of completed internal audits, such as audit reports.\nStep 2: Verify that audits are conducted per the schedule and criteria in the program.\nStep 3: Confirm that audit findings are documented, reported, and linked to corrective action plans.",
      "frequency": "Annually"
  },
  "Clause 9.3": {
      "title": "Management review",
      "description": "Is management review regularly performed, and are the results documented in the minutes of the meeting?\nDid management decide on all the crucial issues important for the success of the ISMS?",
      "testSteps": "Requirement: Is the management review regularly performed, and are the results documented in the minutes of the meeting?\nStep 1: Review evidence of management reviews (e.g., meeting agendas, minutes).\nStep 2: Confirm the periodicity of management reviews and alignment with organizational needs.\nStep 3: Verify that key ISMS issues are addressed and documented.\n\nRequirement: Did management decide on all the crucial issues important for the success of the ISMS?\nStep 1: Examine meeting minutes for decisions on ISMS performance, risks, and improvements.\nStep 2: Validate follow-up actions arising from management reviews.\nStep 3: Confirm that decisions are aligned with strategic ISMS goals.",
      "frequency": "Annually"
  },
  "Clause 10.1": {
      "title": "Nonconformity and Corrective Action",
      "description": "Does the organization react to every nonconformity?\nDoes the organization consider eliminating the cause of nonconformity and, where appropriate, take corrective action?\nAre all nonconformities recorded, together with corrective actions?",
      "testSteps": "Requirement: Does the organization react to every nonconformity?\nStep 1: Review the process for identifying and addressing nonconformities.\nStep 2: Confirm that all nonconformities are logged and acted upon.\n\nRequirement: Does the organization consider eliminating the cause of nonconformity and, where appropriate, take corrective action?\nStep 1: Review root cause analyses for nonconformities.\nStep 2: Verify the implementation and effectiveness of corrective actions.\n\nRequirement: Are all nonconformities recorded, together with corrective actions?\nStep 1: Request evidence of nonconformity logs and corrective action records.\nStep 2: Confirm completeness and traceability of the documentation.",
      "frequency": "Annually"
  }
};
  
  export default ISO4To10FrameworkMapping;