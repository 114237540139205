const config = {
    host: process.env.REACT_APP_API_HOST || 'https://oop8dv9jfj.execute-api.us-east-1.amazonaws.com',
  };
  
  class AuthService {
    static resetPasswordRequest(email) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.host}/reset`, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('Content-Type', 'application/json');
  
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve({
                success: true,
                ...data
              });
            } catch (error) {
              reject(new Error('Invalid response format'));
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              reject(new Error(errorData.message || 'Reset password request failed'));
            } catch (error) {
              reject(new Error('Reset password request failed'));
            }
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Failed to request password reset'));
        };
  
        const data = JSON.stringify({ email });
        xhr.send(data);
      });
    }
  
    static resetPasswordConfirm(token, hashedPassword) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.host}/confirm`, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('Content-Type', 'application/json');
  
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve({
                success: true,
                ...data
              });
            } catch (error) {
              reject(new Error('Invalid response format'));
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              reject(new Error(errorData.message || 'Password reset failed'));
            } catch (error) {
              reject(new Error('Password reset failed'));
            }
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Failed to reset password'));
        };
  
        const data = JSON.stringify({
          token,
          password: hashedPassword,
        });
  
        xhr.send(data);
      });
    }
  
    static login(email, hashedPassword) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.host}/auth`, true);
        xhr.withCredentials = true; // Include cookies
        xhr.setRequestHeader('Content-Type', 'application/json');
  
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve({
                token: data.token,
                user: data.user,
              });
            } catch (error) {
              reject(new Error('Invalid response format'));
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              reject(new Error(errorData.message || 'Authentication failed'));
            } catch (error) {
              reject(new Error('Authentication failed'));
            }
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Failed to authenticate'));
        };
  
        const data = JSON.stringify({
          email,
          password: hashedPassword,
        });
  
        xhr.send(data);
      });
    }
  
    static activate(token, hashedPassword) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.host}/activate`, true);
        xhr.withCredentials = true; // Include cookies
        xhr.setRequestHeader('Content-Type', 'application/json');
  
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve({
                success: true,
                ...data
              });
            } catch (error) {
              reject(new Error('Invalid response format'));
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              reject(new Error(errorData.message || 'Activation failed'));
            } catch (error) {
              reject(new Error('Activation failed'));
            }
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Failed to activate account'));
        };
  
        const data = JSON.stringify({
          token,
          password: hashedPassword,
        });
  
        xhr.send(data);
      });
    }
  
    static signup(email, hashedPassword) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${config.host}/create-user`, true);
        xhr.withCredentials = true; // Include cookies
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onload = function() {
          if (xhr.status >= 200 && xhr.status < 300) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve({
                success: true,
                ...data
              });
            } catch (error) {
              reject(new Error('Invalid response format'));
            }
          } else {
            try {
              const errorData = JSON.parse(xhr.responseText);
              reject(new Error(errorData.message || 'Registration failed'));
            } catch (error) {
              reject(new Error('Registration failed'));
            }
          }
        };
  
        xhr.onerror = function() {
          reject(new Error('Failed to register'));
        };
  
        const data = JSON.stringify({
          email,
          password: hashedPassword,
        });
  
        xhr.send(data);
      });
    }
  }
  
  export default AuthService;