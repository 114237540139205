import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Paper,
  Divider,
  IconButton,
  Collapse
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { WarningOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StatusChip = ({ status }) => {
  const isReject = status === 'REJECT';
  return (
    <Chip
      icon={isReject ? <ErrorOutlineIcon /> : status === 'WARNING' ? <WarningOutlined /> : <CheckCircleOutlineIcon />}
      label={status}
      color={isReject ? 'error' : status === 'WARNING' ? 'warning' : 'success'}
      variant="outlined"
      size="small"
    />
  );
};

const AssessmentSection = ({ title, status, reasoning }) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <StatusChip status={status} />
    </Box>
    <Typography variant="body2" color="text.secondary">
      {reasoning}
    </Typography>
  </Paper>
);

const AnalysisResultsDisplay = ({ analysisResult }) => {
  const [expanded, setExpanded] = useState(true);
  
  if (!analysisResult) return null;

  const {
    overallAssessment,
    organizationIdentification,
    systemProcessIdentification,
    timestamp,
    reasoning
  } = analysisResult;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardContent>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h5">
                  Assessment Results
                </Typography>
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                  size="small"
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <StatusChip status={overallAssessment} />
            </Box>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Overall Assessment"
                  status={overallAssessment}
                  reasoning={reasoning?.overall}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Organization Identification"
                  status={organizationIdentification}
                  reasoning={reasoning?.organization}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="System Process Identification"
                  status={systemProcessIdentification}
                  reasoning={reasoning?.systemProcess}
                />
              </Grid>
              <Grid item xs={12}>
                <AssessmentSection
                  title="Timestamp Verification"
                  status={timestamp}
                  reasoning={reasoning?.timestamp}
                />
              </Grid>
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnalysisResultsDisplay;