const ProjectToProcessMapping = {
    "ITCF Process": [
      "Audit Logging & Security Monitoring",
      "Generiek",
      "Configuration Management", 
      "Data Management",
      "Information Security Management",
      "Malware Security",
      "Penetration Testing & Ethical Hacking",
      "Security Technology",
      "Vulnerability Management"
    ],
    "ITCF System": [
      "Application"
    ]
  };
  
  export default ProjectToProcessMapping;