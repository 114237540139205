import { v4 as uuidv4 } from 'uuid';

const config = {
  host: process.env.REACT_APP_API_HOST || 'https://oop8dv9jfj.execute-api.us-east-1.amazonaws.com',
};

class FrameworkService {
  static getFrameworkInstances() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${config.host}/framework-instance`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              instances: data.instances
            });
          } catch (error) {
            reject(new Error('Failed to parse response data'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Authentication failed'));
          } catch (error) {
            reject(new Error('Authentication failed'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to authenticate'));
      };

      xhr.send();
    });
  }

  static async saveAnalysisResult(analysisResult, control, frameworkUuid, pbc_jwt) {
    const payload = {
      ai_result: analysisResult,
      control,
      framework: frameworkUuid,
      pbc_jwt
    };
    try {
      const response = await fetch(`${config.host}/result`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'auth_token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save analysis result');
      }

      const data = await response.json();
      return {
        success: true,
        ...data
      };

    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Failed to save analysis result');
    }
  }

  static createFrameworkInstance(uuid, title, tags) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/create-framework-instance`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', `${localStorage.getItem('auth_token')}`);

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              success: true,
              ...data
            });
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Registration failed'));
          } catch (error) {
            reject(new Error('Registration failed'));
          }
        }
      };

      xhr.onerror = function () {
        reject(new Error('Failed to register'));
      };

      const data = JSON.stringify({
        uuid,
        title,
        tags
      });

      xhr.send(data);
    });
  }

  static createFrameworkTemplate(type, controls) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/create-framework-template`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', `${localStorage.getItem('auth_token')}`);

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            // Return the response data directly, which includes message, uuid, type, and controls
            resolve(data);
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Framework template creation failed'));
          } catch (error) {
            reject(new Error('Framework template creation failed'));
          }
        }
      };

      xhr.onerror = function () {
        reject(new Error('Failed to create framework template'));
      };

      const data = JSON.stringify({
        uuid: uuidv4(),
        type,
        controls,
      });

      xhr.send(data);
    });
  }

  static shareFramework(frameworkUuid, email) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${config.host}/share`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('auth_token', localStorage.getItem('auth_token'));

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            resolve({
              success: true,
              ...data
            });
          } catch (error) {
            reject(new Error('Invalid response format'));
          }
        } else {
          try {
            const errorData = JSON.parse(xhr.responseText);
            reject(new Error(errorData.message || 'Failed to share framework'));
          } catch (error) {
            reject(new Error('Failed to share framework'));
          }
        }
      };

      xhr.onerror = function() {
        reject(new Error('Failed to share framework'));
      };

      const data = JSON.stringify({
        uuid: frameworkUuid,
        email
      });

      xhr.send(data);
    });
  }
}

export default FrameworkService;