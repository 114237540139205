const ProcessToControlMapping = {
    "Audit Logging & Security Monitoring": [
      { id: "ALSM-01", title: "MSSP" },
      { id: "ALSM-02", title: "Logging" },
      { id: "ALSM-03", title: "NTP" }
    ],
    "Generiek": [
      { id: "CLAS-01", title: "Review procesbeschrijving en richtlijnen" },
      { id: "CM-01", title: "Herijking procesbeschrijving Functional Change Management" },
      { id: "HRM-07", title: "C - Functieprofielen" },
      { id: "HRM-08", title: "C- LMS" },
      { id: "IAM-01", title: "Review procesbeschrijving en richtlijnen" }
    ],
    "Configuration Management": [
      { id: "ConfM-01", title: "Procesbeschrijving Configuration Management" },
      { id: "ConfM-03", title: "Wijzigingen CMDB" }
    ],
    "Data Management": [
      { id: "DM-03", title: "USB Bitlocker" }
    ],
    "Information Security Management": [
      { id: "ISM-01", title: "Strategisch en tactisch informatiebeveiligingsbeleid" },
      { id: "ISM-02", title: "IBB communicatie en beschikbaarheid" },
      { id: "ISM-03", title: "Group Security Forum" },
      { id: "ISM-04", title: "Directiecommunicatie over informatiebeveiliging" },
      { id: "ISM-05", title: "Security Roadmap" },
      { id: "ISM-06", title: "Rapportage compliance DNB" },
      { id: "ISM-07", title: "Standaard Cryptografie" },
      { id: "ISM-08", title: "Beleid Mobiele Apparatuur" },
      { id: "ISM-09", title: "Beleid Telewerken" }
    ],
    "Application": [
      { id: "CLAS-02", title: "Vaststellen BIV-classificatie" },
      { id: "CLAS-04", title: "Monitoring BIV-classificaties door ISO" },
      { id: "CM-02", title: "Scheiding OTAP-omgevingen" },
      { id: "CM-03", title: "Testen met anonieme data" },
      { id: "CM-04", title: "Testen van wijzigingen" },
      { id: "CM-05", title: "Functiescheiding bij inproductiename" },
      { id: "IAM-02", title: "Review autorisatiematrix" },
      { id: "IAM-03", title: "Aanmaken AD-accounts" },
      { id: "IAM-04", title: "Intrekken AD accounts" },
      { id: "IAM-05", title: "Toekennen rechten" },
      { id: "IAM-07", title: "Intrekken rechten" },
      { id: "IAM-08", title: "Kwartaallijkse rechtencontrole" },
      { id: "IAM-09", title: "Controle authenticatie-mechanismen" },
      { id: "IAM-10", title: "Controle NPG-accounts" },
      { id: "IAM-11", title: "Controle geautomatiseerde Entra-groepen" },
      { id: "IAM-12", title: "Controle rechten database" },
      { id: "IAM-13", title: "Controle rechten server" },
      { id: "IAM-14", title: "Controle rechten OTA-omgevingen" }
    ],
    "Malware Security": [
      { id: "MS-01", title: "Periodieke evaluatie procesbeschrijving" },
      { id: "MS-02", title: "Dekkingsgraad anti-Malware" },
      { id: "MS-03", title: "Identificatie false positives" },
      { id: "MS-04", title: "Actuele signature file IPS" },
      { id: "MS-05", title: "Actuele signature file anti-Malware" },
      { id: "MS-06", title: "Goedkeuring wijziging XDR-policy" },
      { id: "MS-07", title: "Monitoren actueel dreigingsbeeld" },
      { id: "MS-10", title: "Blokkeren incompliant clients" }
    ],
    "Penetration Testing & Ethical Hacking": [
      { id: "PTEH-01", title: "Jaarplan Pentesting" },
      { id: "PTEH-02", title: "Pentest Nieuwe Applicaties" },
      { id: "PTEH-03", title: "Pentest Bestaande Applicaties" }
    ],
    "Security Technology": [
      { id: "ST-2", title: "Periodieke evaluatie standaard security technology" },
      { id: "ST-3", title: "Periodiek herzien classificatie van documentatie en toegangsrechten" },
      { id: "ST-4", title: "Periodieke controle toegangsrechten security technology" },
      { id: "ST-5", title: "Periodieke evaluatie security stack" },
      { id: "ST-6", title: "Periodieke evaluatie procesbeschrijving" },
      { id: "ST-8", title: "Netwerk Security Scan" }
    ],
    "Vulnerability Management": [
      { id: "VM-03", title: "Threat intelligence overzicht" },
      { id: "VM-06", title: "Update proces Vulnerability Management" },
      { id: "VM-07", title: "Vulnerability Reporting" },
      { id: "VM-08", title: "Vulnerability Monitoring" },
      { id: "VM-09", title: "Monitoring tooling" },
      { id: "VM-10", title: "Determine EDBC-rating" },
      { id: "VM-11", title: "Vulnerability opvolging" },
      { id: "VM-13", title: "Vulnerability Scanning" },
      { id: "VM-14", title: "Updates vulnerability scanning" }
    ]
  };
  
  export default ProcessToControlMapping;