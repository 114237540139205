import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  Container,
  Stack,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../services/auth_service';

// Create SHA-256 hash function
const sha256 = async (message) => {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

// JWT decode function (without validation)
const decodeJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

const theme = createTheme();

const ActivateForm = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [serverMessage, setServerMessage] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jwtToken = params.get('token');

    if (!jwtToken) {
      setServerMessage({ type: 'error', message: 'No activation token provided' });
      return;
    }

    const payload = decodeJWT(jwtToken);
    if (!payload) {
      setServerMessage({ type: 'error', message: 'Invalid activation token' });
      return;
    }

    // Check if token is expired
    const currentTime = Math.floor(Date.now() / 1000);
    if (payload.exp && payload.exp < currentTime) {
      setIsExpired(true);
      setServerMessage({ type: 'error', message: 'This activation link has expired' });
      return;
    }

    setEmail(payload.email);
    setToken(jwtToken);
  }, [location]);

  const validateForm = () => {
    const newErrors = {};
    
    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    // Confirm password validation
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm() || isExpired) {
      return;
    }
    
    setIsLoading(true);
    setServerMessage({ type: '', message: '' });
    
    try {
      // Hash the password
      const hashedPassword = await sha256(formData.password);
      
      // Call auth service
      const response = await AuthService.activate(token, hashedPassword);
      
      if (response.success) {
        setServerMessage({
          type: 'success',
          message: 'Account activated successfully! Redirecting to login...'
        });
        
        // Redirect to login after a short delay
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setServerMessage({
          type: 'error',
          message: response.message || 'Activation failed. Please try again.'
        });
      }
    } catch (err) {
      setServerMessage({
        type: 'error',
        message: err.message || 'An error occurred during activation'
      });
      console.error('Activation error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              marginTop: 16,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img 
                src="logo.png" 
                alt="Logo"
                style={{ maxWidth: '370px', height: 'auto' }}
              />
            </Box>

            <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
              Activate Account: {email}
            </Typography>

            {serverMessage.message && (
              <Alert 
                severity={serverMessage.type} 
                sx={{ width: '100%', mb: 3 }}
              >
                {serverMessage.message}
              </Alert>
            )}

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Stack spacing={3}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  disabled={isExpired}
                />

                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  disabled={isExpired}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={isLoading || isExpired}
                  sx={{
                    mt: 2,
                    bgcolor: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  }}
                >
                  {isLoading ? 'Activating Account...' : 'Activate Account'}
                </Button>

                <Button
                  fullWidth
                  variant="text"
                  onClick={() => navigate('/login')}
                  sx={{ mt: 1 }}
                >
                  Back to Login
                </Button>
              </Stack>
            </form>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ActivateForm;