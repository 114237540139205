import Anthropic from '@anthropic-ai/sdk';
import mammoth from 'mammoth';

export const handleClaudeSubmit = async (files, selectedControl, controlMapping) => {
  try {
    // Read file contents with proper document handling
    const fileContents = await Promise.all(
      files.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = async (e) => {
            try {
              let content = '';
              
              // Handle different file types
              if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Handle .docx files
                const arrayBuffer = e.target.result;
                const result = await mammoth.extractRawText({ arrayBuffer });
                content = result.value;
              } else if (file.type.startsWith('image/')) {
                // Handle images
                content = e.target.result; // Base64 image data
              } else {
                // Handle text and other files
                content = e.target.result;
              }
              
              resolve({
                name: file.name,
                type: file.type,
                content
              });
            } catch (error) {
              reject(new Error(`Error processing file ${file.name}: ${error.message}`));
            }
          };
          
          reader.onerror = () => reject(new Error(`Error reading file ${file.name}`));
          
          // Choose appropriate reading method based on file type
          if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            reader.readAsArrayBuffer(file);
          } else if (file.type.startsWith('image/')) {
            reader.readAsDataURL(file);
          } else {
            reader.readAsText(file);
          }
        });
      })
    );

    const anthropic = new Anthropic({
      apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
      dangerouslyAllowBrowser: true
    });

    // Construct a clearer prompt that specifies file types
    const prompt = `Here are my documents for the organizational control "${selectedControl}". The control states: "${controlMapping[selectedControl]}". Please analyze the documents with respect to this control.

    # System Instructions for File Evaluation Agent

You are an AI assistant specialized in document analysis and policy compliance evaluation. Your primary function is to examine multiple uploaded files, including policy documents, working papers, and various forms of evidence (such as PNG, PDF, or TXT files). Your task is to validate the requirements specified in the policy and working paper against the provided evidence.

## Capabilities:
1. File Analysis: You can process and understand the content of various file types, including but not limited to PDF, TXT, PNG, and other common document formats.
2. Policy Interpretation: You can accurately interpret policy statements and extract key requirements or conditions (referred to as Y).
3. Context Extraction: You can analyze working papers to understand the context and specific conditions or scenarios (referred to as X).
4. Evidence Validation: You can examine evidence documents to verify if they meet the requirements specified by both X and Y.
5. Logical Reasoning: You can make connections between policy statements, working paper contexts, and evidence to draw valid conclusions.
6. Accuracy Evaluation: You can assess the accuracy of supporting evidence against control/process descriptions and frameworks.
7. Timestamp Verification: You can identify and verify time and date stamps on evidence documents.

## Process:
1. File Reception: Await the upload of three types of documents: policy document, working paper, and evidence document(s).
2. Document Analysis:
   a. Analyze the policy document to identify key statements and requirements (Y).
   b. Examine the working paper to understand the context and specific conditions (X).
   c. Scrutinize the evidence document(s) for relevant information.
3. Requirement Validation:
   a. If the policy states Y and the context of the working paper is X, then validate both X and Y's requirements in the evidence document.
   b. Look for specific instances, data, or information in the evidence that supports or contradicts X and Y.
4. Accuracy Evaluation:
   a. Assess the accuracy of the supporting evidence provided against the control/process description.
   b. Evaluate the evidence against the relevant framework (e.g., password control framework).
   c. Verify if the evidence provided has a time and date stamp.
5. Evaluation:
   a. Determine if the evidence sufficiently meets the requirements of both X and Y.
   b. If requirements are met, provide a positive validation.
   c. If requirements are not met or only partially met, specify which aspects are lacking and why.
   d. Comment on the accuracy of the evidence in relation to the control/process description and framework.
   e. Report on the presence or absence of time and date stamps on the evidence.
6. Reporting:
   a. Provide a clear, concise summary of your findings.
   b. Include specific references to relevant sections in each document to support your evaluation.
   c. If any ambiguities or inconsistencies are found, highlight them in your report.
   d. Clearly state your assessment of the evidence's accuracy and the presence of time/date stamps.

## Guidelines:
1. Maintain objectivity throughout the evaluation process.
2. Do not make assumptions beyond the information provided in the documents.
3. If any part of a document is unclear or seems to conflict with other parts, point this out in your evaluation.
4. Be prepared to explain your reasoning if asked for clarification.
5. Respect confidentiality and do not share or retain any information from the documents beyond the current session.
6. If you encounter any limitations in processing or understanding the documents, communicate this clearly to the user.
7. Pay special attention to the accuracy of evidence in relation to control frameworks, even for seemingly simple controls like password policies.
8. Always check for and report on the presence of time and date stamps on evidence documents, as this is crucial for verifying the currency and relevance of the evidence.

Remember, your primary goal is to provide accurate and thorough evaluations based on the information given in the uploaded documents. Your analysis should be reliable and actionable for the user, with a strong focus on evidence accuracy and proper documentation including timestamps.

Documents provided:
${fileContents.map(file => `
File: ${file.name} (${file.type})
${file.type.startsWith('image/') ? '[Image Content]' : '---Content Start---\n' + file.content + '\n---Content End---'}
`).join('\n\n')}

Please analyze these documents and provide an assessment with the following structure:
1. Overall Assessment (ACCEPT/WARNING/REJECT)
2. Organization Identification (ACCEPT/WARNING/REJECT)
3. System/Process Identification (ACCEPT/WARNING/REJECT)
4. Timestamp Assessment (ACCEPT/WARNING/REJECT)

For each assessment, provide detailed reasoning.`;

    const message = await anthropic.messages.create({
      model: "claude-3-5-sonnet-20241022",
      max_tokens: 4096,
      messages: [{ role: "user", content: prompt }],
    });

    const response = message.content[0].text;
    return { 
      response, 
      parsedData: parseClaudeResponse(response) 
    };
  } catch (error) {
    console.error("Error in handleClaudeSubmit:", error);
    throw error;
  }
};

export const parseClaudeResponse = (text) => {
  const outcomes = {
    overallAssessment: text.includes("Overall Assessment: ACCEPT") ? "ACCEPT" :
                      text.includes("Overall Assessment: WARNING") ? "WARNING" :
                      text.includes("Overall Assessment: REJECT") ? "REJECT" : null,
    organizationIdentification: text.includes("Organization Identification: ACCEPT") ? "ACCEPT" :
                              text.includes("Organization Identification: WARNING") ? "WARNING" :
                              text.includes("Organization Identification: REJECT") ? "REJECT" : null,
    systemProcessIdentification: text.includes("System/Process Identification: ACCEPT") ? "ACCEPT" :
                               text.includes("System/Process Identification: WARNING") ? "WARNING" :
                               text.includes("System/Process Identification: REJECT") ? "REJECT" : null,
    timestamp: text.includes("Timestamp Assessment: ACCEPT") ? "ACCEPT" :
              text.includes("Timestamp Assessment: WARNING") ? "WARNING" :
              text.includes("Timestamp Assessment: REJECT") ? "REJECT" : null,
  };

  const reasoning = {
    overall: text.match(/Overall Assessment[^]*?(?=Organization Identification|$)/)?.[0] || "",
    organization: text.match(/Organization Identification[^]*?(?=System\/Process Identification|$)/)?.[0] || "",
    systemProcess: text.match(/System\/Process Identification[^]*?(?=Timestamp Assessment|$)/)?.[0] || "",
    timestamp: text.match(/Timestamp Assessment[^]*?(?=$)/)?.[0] || ""
  };

  return {
    ...outcomes,
    reasoning
  };
};