import React from 'react';
import { Box, Tabs, Tab, Paper, Chip } from '@mui/material';

const UserTabs = ({ users, selectedTab, onTabChange }) => {
  const activeUsers = users.filter(user => user.is_active);
  const inactiveUsers = users.filter(user => !user.is_active);
  
  const handleChange = (event, newValue) => {
    onTabChange(newValue);
  };

  return (
    <Paper sx={{ mb: 3 }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        sx={{ 
          minHeight: 48,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': { 
            minHeight: 48,
            minWidth: 'auto',
            px: 3
          }
        }}
      >
        <Tab 
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              All <Chip label={users.length} size="small" sx={{ bgcolor: 'grey.700', color: 'white' }} />
            </Box>
          }
          value="all"
        />
        <Tab 
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Active <Chip label={activeUsers.length} size="small" color="success" />
            </Box>
          }
          value="active"
        />
        <Tab 
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              Inactive <Chip label={inactiveUsers.length} size="small" color="error" />
            </Box>
          }
          value="inactive"
        />
      </Tabs>
    </Paper>
  );
};

export default UserTabs;