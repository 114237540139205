import React from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';

const MenuButtons = ({ 
  handleMenuOpen, 
  filterMenuOpen, 
  downloadCSV, 
  fetchUsers 
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    // { icon: <ViewColumnIcon />, text: 'Columns', onClick: (event) => { handleMenuOpen(event.currentTarget); handleClose(); } },
    // { icon: <FilterListIcon />, text: 'Filters', onClick: (event) => { filterMenuOpen(event.currentTarget); handleClose(); } },
    { icon: <FileDownloadIcon />, text: 'Export', onClick: () => { downloadCSV(); handleClose(); } },
    { icon: <RefreshIcon />, text: 'Refresh', onClick: () => { fetchUsers(); handleClose(); } },
  ];

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuButtons;