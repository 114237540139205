import Anthropic from '@anthropic-ai/sdk';
import mammoth from 'mammoth';

export const handleClaudeSubmit = async (files, selectedSubcategory, frameworkData) => {
  try {
    // Read file contents with proper document handling
    const fileContents = await Promise.all(
      files.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = async (e) => {
            try {
              let content = '';
              
              // Handle different file types
              if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                // Handle .docx files
                const arrayBuffer = e.target.result;
                const result = await mammoth.extractRawText({ arrayBuffer });
                content = result.value;
              } else if (file.type.startsWith('image/')) {
                // Handle images
                content = e.target.result; // Base64 image data
              } else {
                // Handle text and other files
                content = e.target.result;
              }
              
              resolve({
                name: file.name,
                type: file.type,
                content
              });
            } catch (error) {
              reject(new Error(`Error processing file ${file.name}: ${error.message}`));
            }
          };
          
          reader.onerror = () => reject(new Error(`Error reading file ${file.name}`));
          
          // Choose appropriate reading method based on file type
          if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            reader.readAsArrayBuffer(file);
          } else if (file.type.startsWith('image/')) {
            reader.readAsDataURL(file);
          } else {
            reader.readAsText(file);
          }
        });
      })
    );

    const anthropic = new Anthropic({
      apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
      dangerouslyAllowBrowser: true
    });

    // Construct prompt for NIST framework analysis
    const prompt = `Here are my documents for analysis against NIST CSF Subcategory "${selectedSubcategory}".

Framework Details:
Category: ${frameworkData.category}
Subcategory: ${frameworkData.subcategory}
Title: ${frameworkData.details.title}

Implementation Examples:
${frameworkData.details.examples.map((example, index) => `${index + 1}. ${example}`).join('\n')}

# System Instructions for NIST CSF Evaluation Agent

You are an AI assistant specialized in NIST Cybersecurity Framework compliance evaluation. Your primary function is to examine multiple uploaded files and assess their alignment with NIST CSF requirements, particularly focusing on implementation evidence for specific subcategories.

## Capabilities:
1. File Analysis: Process various file types including policy documents, screenshots, logs, and other evidence formats
2. Framework Interpretation: Accurately interpret NIST CSF requirements and implementation examples
3. Evidence Validation: Examine evidence against framework requirements
4. Maturity Assessment: Evaluate the maturity level of implementations
5. Gap Analysis: Identify areas where implementation could be improved
6. Timestamp Verification: Identify and verify time/date stamps on evidence documents

## Process:
1. Framework Analysis:
   a. Understand the specific requirements of the NIST CSF subcategory
   b. Review provided implementation examples as reference points
   c. Identify key elements that evidence should demonstrate

2. Document Analysis:
   a. Review all provided documents
   b. Map evidence to framework requirements
   c. Assess implementation quality
   d. Verify timestamps and currency of evidence

3. Assessment Areas:
   a. Overall alignment with NIST requirements
   b. Implementation quality against examples
   c. Organizational integration
   d. Documentation completeness

## Guidelines:
1. Focus on concrete evidence of implementation
2. Consider both technical and procedural aspects
3. Evaluate sustainability and maturity of implementations
4. Verify documentation currency and relevance
5. Identify both strengths and improvement areas
6. Maintain objectivity in assessment

Documents provided:
${fileContents.map(file => `
File: ${file.name} (${file.type})
${file.type.startsWith('image/') ? '[Image Content]' : '---Content Start---\n' + file.content + '\n---Content End---'}
`).join('\n\n')}

Please analyze these documents and provide an assessment with the following structure:
1. Overall Assessment (ACCEPT/WARNING/REJECT)
2. Organization Identification (ACCEPT/WARNING/REJECT)
3. System/Process Identification (ACCEPT/WARNING/REJECT)
4. Timestamp Assessment (ACCEPT/WARNING/REJECT)

For each assessment category:
- Provide detailed reasoning
- Reference specific evidence from documents
- Compare against NIST CSF requirements
- Identify gaps or areas for improvement
- Consider implementation maturity level`;

    const message = await anthropic.messages.create({
      model: "claude-3-5-sonnet-20241022",
      max_tokens: 4096,
      messages: [{ role: "user", content: prompt }],
    });

    const response = message.content[0].text;
    return { 
      response, 
      parsedData: parseClaudeResponse(response) 
    };
  } catch (error) {
    console.error("Error in handleClaudeSubmit:", error);
    throw error;
  }
};

export const parseClaudeResponse = (text) => {
  const outcomes = {
    overallAssessment: text.includes("Overall Assessment: ACCEPT") ? "ACCEPT" :
                      text.includes("Overall Assessment: WARNING") ? "WARNING" :
                      text.includes("Overall Assessment: REJECT") ? "REJECT" : null,
    organizationIdentification: text.includes("Organization Identification: ACCEPT") ? "ACCEPT" :
                              text.includes("Organization Identification: WARNING") ? "WARNING" :
                              text.includes("Organization Identification: REJECT") ? "REJECT" : null,
    systemProcessIdentification: text.includes("System/Process Identification: ACCEPT") ? "ACCEPT" :
                               text.includes("System/Process Identification: WARNING") ? "WARNING" :
                               text.includes("System/Process Identification: REJECT") ? "REJECT" : null,
    timestamp: text.includes("Timestamp Assessment: ACCEPT") ? "ACCEPT" :
              text.includes("Timestamp Assessment: WARNING") ? "WARNING" :
              text.includes("Timestamp Assessment: REJECT") ? "REJECT" : null,
  };

  const reasoning = {
    overall: text.match(/Overall Assessment[^]*?(?=Organization Identification|$)/)?.[0] || "",
    organization: text.match(/Organization Identification[^]*?(?=System\/Process Identification|$)/)?.[0] || "",
    systemProcess: text.match(/System\/Process Identification[^]*?(?=Timestamp Assessment|$)/)?.[0] || "",
    timestamp: text.match(/Timestamp Assessment[^]*?(?=$)/)?.[0] || ""
  };

  return {
    ...outcomes,
    reasoning
  };
};