import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import MarketingPage from './marketing-page/MarketingPage';
import UnifiedAIForm from './assistant-forms/AssistantView';
import LoginForm from './auth/LoginForm';
import JwtAuthForm from './auth/JwtAuthForm';
import ActivateForm from './auth/ActivateForm';
import SignUpForm from './auth/SignUpForm';
import ResetPasswordRequestForm from './auth/ResetPasswordRequestForm';
import ResetPasswordConfirmForm from './auth/ResetPasswordConfirmForm';
import FrameworksPage from './frameworks/FrameworksPage';
import UsersPage from './users/UsersPage';
import PBCList from './assistant-forms/PBCList';
import ISOPBCList from './assistant-forms/ISO_PBCList';
import NIST_PBCList from './assistant-forms/NIST_PBCList';
import DashboardLayout from './dashboard/DashboardLayout';

// Import Montserrat font
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const isValidToken = () => {
    try {
      const token = localStorage.getItem('auth_token');
      
      if (!token) return false;
      
      // Decode the JWT token (split by dots and decode middle section)
      const [, payloadBase64] = token.split('.');
      const payload = JSON.parse(atob(payloadBase64));
      
      // Check if token has expired
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      if (payload.exp && payload.exp < currentTime) {
        // Token has expired, remove it
        localStorage.removeItem('auth_token');
        return false;
      }
      
      // Additional claims validation can be added here
      // For example, checking specific roles or permissions
      // if (!payload.roles.includes('required-role')) return false;
      return true;
    } catch (error) {
      console.error('Token validation error:', error);
      localStorage.removeItem('auth_token');
      return false;
    }
  };

  if (!isValidToken()) {
    // Redirect to login page with the return url
    return (
      <Navigate 
        to="/login" 
        replace 
        state={{ from: window.location.pathname }}
      />
    );
  }

  return children;
};

// Create a custom theme with the provided color palette and Montserrat font
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    allVariants: {
      fontFamily: 'Montserrat, "Montserrat Fallback", sans-serif',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#ed7d31', // color_3
    },
    secondary: {
      main: '#ed7d31', // color_3
    },
    text: {
      primary: 'rgba(0, 0, 0, 1)', // color_2
      secondary: 'rgba(102, 102, 102, 1)', // color_4
    },
    background: {
      default: 'rgba(247, 247, 247, 1)', // color_6
      paper: 'rgba(255, 255, 255, 1)', // color_1
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat Fallback';
          src: local('Arial');
        }
        * {
          font-family: 'Montserrat', 'Montserrat Fallback', sans-serif !important;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px !important',
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<MarketingPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/jwt-login" element={<JwtAuthForm />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path='/activate' element={<ActivateForm />} />
          <Route path='/reset-password-request' element={<ResetPasswordRequestForm />} />
          <Route path='/reset-password' element={<ResetPasswordConfirmForm />} />
          
          {/* PBC List routes */}
          <Route path="/pbc-list" element={<PBCList />} />
          <Route path="/iso-pbc-list" element={<ISOPBCList />} />
          <Route path="/nist-pbc-list" element={<NIST_PBCList />} />

          {/* Protected Dashboard routes */}
          <Route
            element={
              <ProtectedRoute>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/agents" element={<UnifiedAIForm />} />
            <Route path="/frameworks" element={<FrameworksPage />} />
            <Route path="/users" element={<UsersPage />} />
          </Route>

          {/* Catch all route - redirect to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;