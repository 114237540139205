import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Snackbar,
  IconButton,
  Tooltip,
  Chip,
  Collapse,
  Card,
  CardContent,
  Autocomplete,
  InputLabel,
  FormControl,
  Select,
  Grid,
  Switch,
  FormControlLabel,
  Menu,
  Checkbox,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { handleClaudeSubmit } from '../assistant-forms//modules/custom_claude_sonnet_3.5_module';
import { handleOpenAISubmit, validateFiles } from '../assistant-forms//modules/custom_openai_4o_module';
import AnalysisResultsDisplay from './AnalysisResultsDisplay';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Download } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FrameworkService from '../services/framework_service';

import ISOFrameworkMapping from '../assistant-forms/ISO_4_10_framework_mapping';
import CustomFrameworkMapping from '../assistant-forms/custom_audit_checklist';
import NISTFrameworkMapping from '../assistant-forms/nist_framework_mapping';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import { FilterList, WarningOutlined } from '@mui/icons-material';

import { saveAs } from 'file-saver';

const FRAMEWORK_TYPES = ['NIST', 'ISO 27001 Clause 4 to 10', 'Custom'];

const STATUS_OPTIONS = ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'BLOCKED'];

const AI_TYPES = {
  'claude': 'Claude',
  'openai': 'OpenAI'
};

const getControlFrequencyColor = (frequency) => {
  switch (frequency?.toLowerCase()) {
    case 'continuous':
      return 'success';
    case 'monthly':
      return 'primary';
    case 'quarterly':
      return 'warning';
    case 'annually':
      return 'error';
    default:
      return 'default';
  }
};

const CustomFileInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const FileInputButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[100],
  },
}));

const FileNameDisplay = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.background.paper,
  '& .MuiCardContent-root': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const ControlCard = ({ 
  control, 
  controlDetails, 
  instance, 
  onAnalysisComplete, 
  selectedAI,
  onUpdateDates,
  onUpdateAutomated 
}) => {
  const theme = useTheme();
  const possibleResult = instance.results.find(result => result.control === control);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(possibleResult?.ai_result);
  const [isExpanded, setIsExpanded] = useState(false);
  const fileInputRef = useRef(null);

  const StatusChip = ({ status }) => {
    const isReject = status === 'REJECT';
    return (
      <Chip
        icon={isReject ? <ErrorOutlineIcon /> : status === 'WARNING' ? <WarningOutlined /> : <CheckCircleOutlineIcon />}
        label={status}
        color={isReject ? 'error' : status === 'WARNING' ? 'warning' : 'success'}
        variant="outlined"
        size="small"
      />
    );
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    
    if (selectedAI === 'openai') {
      const { isValid, errors } = validateFiles(newFiles);
      if (!isValid) {
        setError(errors.join('\n'));
        return;
      }
    }
    
    setFiles(newFiles);
    setError(null);
  };

  const setResult = async (analysisResult) => {
    await FrameworkService.saveAnalysisResult(analysisResult, control, instance.uuid);
    setAnalysisResult(analysisResult);
  };

  const handleAnalyze = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const modifiedControl = {
        ...controlDetails,
        id: control
      };

      const response = selectedAI === 'claude' 
        ? await handleClaudeSubmit(files, control, { [control]: modifiedControl }, setResult)
        : await handleOpenAISubmit(files, control, { [control]: modifiedControl }, setResult);
      if (onAnalysisComplete) {
        onAnalysisComplete(control, response);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFileNames = (files) => {
    if (files.length === 0) return 'No file chosen';
    return files.map(file => file.name).join(', ');
  };

  return (
    <Grid item xs={12} md={instance.controls.length > 1 ? 6 : 12}>
      <StyledCard>
        <CardContent className="pt-4 pb-2">
        <div className="flex items-center gap-4 mb-2">
            <Typography variant="h5" className="flex-grow">
              {control}
              <Chip
                label={controlDetails.frequency}
                color={getControlFrequencyColor(controlDetails.frequency)}
                size="small"
                sx={{ ml: 1, mr: 1 }}
              />
              {analysisResult?.overallAssessment && <StatusChip status={analysisResult?.overallAssessment}/>}
              <IconButton 
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Typography>
            
            <Typography variant="h7" className="flex-grow">
              {controlDetails.title}
            </Typography>
          </div>
          
          <Collapse in={isExpanded}>
            <div>
              <Typography variant="body2" color="text.secondary" className="mb-4" sx={{mt: 2, mb: 2}}>
                {controlDetails.description}
              </Typography>

              {/* Analysis Results */}
              {analysisResult && (
                <AnalysisResultsDisplay analysisResult={analysisResult} />
              )}
              
              {/* File Upload Section */}
              <Box className="mt-4">
                <CustomFileInput>
                  <HiddenInput
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                  />
                  <FileInputButton
                    variant="outlined"
                    sx={{mt: 2}}
                    component="span"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload Evidence
                  </FileInputButton>
                  <FileNameDisplay variant="body2">
                    {renderFileNames(files)}
                  </FileNameDisplay>
                </CustomFileInput>

                {error && (
                  <Alert severity="error" className="mt-2">
                    {error}
                  </Alert>
                )}

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleAnalyze}
                  disabled={files.length === 0 || isLoading}
                  className="mt-2"
                  sx={{mt: 2}}
                >
                  {isLoading ? <CircularProgress size={24} /> : 'Analyze Evidence'}
                </Button>
              </Box>
            </div>
          </Collapse>
        </CardContent>
      </StyledCard>
    </Grid>
  );
};

const FrameworksPage = () => {
  const theme = useTheme();
  const [selectedAI, setSelectedAI] = useState('openai');
  const [analysisResults, setAnalysisResults] = useState({});
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    title: true,
    type: true,
    controls: true,
    testingStatus: true,
    reviewStatus: true,
    tags: true,
    created_by: true,
    created_at: true,
    actions: true,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formData, setFormData] = useState({
    type: 'NIST',
    controls: ''
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedControl, setSelectedControl] = useState(null);
  const [availableControls, setAvailableControls] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [shareEmail, setShareEmail] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [selectedFrameworkType, setSelectedFrameworkType] = useState('');
  const [filters, setFilters] = useState({
    title: '',
    type: '',
    controls: '',
    testingStatus: '',
    reviewStatus: '',
    tags: '',
    created_by: '',
    created_at: '',
  });

  const filteredInstances = instances.filter((instance) => {
    const matchesSearch = 
      instance.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase())) ||
      instance.created_by.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.testingStatus.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.reviewStatus.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.created_at.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      instance.controls.some((control) => control.toLowerCase().includes(searchQuery.toLowerCase()));
  
    const matchesType = !selectedFrameworkType || instance.type === selectedFrameworkType;
  
    return matchesSearch && matchesType;
  });

  const toggleColumnVisibility = (column) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const columns = [
    { id: 'title', label: 'Title' },
    { id: 'type', label: 'Type' },
    { id: 'controls', label: 'Controls' },
    { id: 'testingStatus', label: 'Testing Status' },
    { id: 'reviewStatus', label: 'Review Status' },
    { id: 'tags', label: 'Tags' },
    { id: 'created_by', label: 'Owner' },
    { id: 'created_at', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
  ];

  const operators = ['Contains', 'Equals', 'Starts With', 'Ends With'];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const applyFilter = () => {
    setFilterAnchorEl(null);
    const { column, operator, value } = filters;

    if (column && operator && value) {
      const filteredData = instances.filter((instance) => {
        const cellValue = instance[column]?.toString().toLowerCase();
        const filterValue = value.toLowerCase();

        switch (operator) {
          case 'Contains':
            return cellValue.includes(filterValue);
          case 'Equals':
            return cellValue === filterValue;
          case 'Starts With':
            return cellValue.startsWith(filterValue);
          case 'Ends With':
            return cellValue.endsWith(filterValue);
          default:
            return true;
        }
      });

      setInstances(filteredData);
    }
  };

  const filterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  const downloadCSV = () => {
    const csvHeaders = columns
      .filter(column => columnVisibility[column.id])
      .map(column => column.label)
      .join(',');
  
    const csvRows = filteredInstances.map(instance => {
      return columns
      .filter(column => columnVisibility[column.id])
        .map(column => {
          if (column.id === 'controls') {
            return instance.controls.join(';'); // Join control names with a semicolon
          }
          else if (column.id === 'tags') {
            return instance.tags.join(';');
          }
          return instance[column.id] || ''; // Use instance data or empty string for missing data
        })
        .join(',');
    });
  
    const csvContent = [csvHeaders, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'framework_data.csv');
  };

  const getUserInfo = () => {
    try {
      const token = localStorage.getItem('auth_token');
      if (!token) return null;
      const [, payloadBase64] = token.split('.');
      return JSON.parse(atob(payloadBase64));
    } catch (error) {
      return null;
    }
  };
  const userInfo = getUserInfo();

  const handleShare = async () => {
    try {
      setIsSharing(true);
      await FrameworkService.shareFramework(selectedFramework.uuid, shareEmail);
      setSnackbar({
        open: true,
        message: 'Framework shared successfully',
        severity: 'success'
      });
      setShareDialogOpen(false);
      setShareEmail('');
      setSelectedFramework(null);
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to share framework',
        severity: 'error'
      });
    } finally {
      setIsSharing(false);
    }
  };

  const openShareDialog = (framework) => {
    setSelectedFramework(framework);
    setShareDialogOpen(true);
  };
  
  const handleAnalysisComplete = (control, result) => {
    setAnalysisResults(prev => ({
      ...prev,
      [control]: result
    }));
  };

  const getFrameworkMapping = (type) => {
    switch (type) {
      case 'ISO 27001 Clause 4 to 10':
        return ISOFrameworkMapping;
      case 'Custom':
        return CustomFrameworkMapping;
      case 'NIST':
        return NISTFrameworkMapping;
      default:
        return {};
    }
  };

  const handleFilterChange = (column, value) => {
    setFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  useEffect(() => {
    const mapping = getFrameworkMapping(formData.type);
    const controls = Object.keys(mapping).map(key => ({
      id: key,
      ...mapping[key]
    }));
    setAvailableControls(controls);
  }, [formData.type]);

  const fetchInstances = async () => {
    try {
      setLoading(true);
      const response = await FrameworkService.getFrameworkInstances();
      setInstances(response['instances']);
      for(let instance of response['instances']) {
        for(let result of instance.results) {
          handleAnalysisComplete(result.control, result.ai_result);
        }
      }
      setError(null);
    } catch (err) {
      setError(err.message || 'Failed to fetch framework instances');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstances();
  }, []);

  const validateControls = (controls) => {
    const mapping = getFrameworkMapping(formData.type);
    const invalidControls = controls.filter(control => !mapping[control]);
    if (invalidControls.length > 0) {
      throw new Error(`Invalid controls for ${formData.type} framework: ${invalidControls.join(', ')}`);
    }
    return true;
  };

  const handleCreateTemplate = async () => {
    try {
      setIsCreating(true);
      const controls = formData.controls.split(',').map(control => control.trim());
      validateControls(controls);
      const response = await FrameworkService.createFrameworkTemplate(formData.type, controls);
      const uuid = response.uuid;
      await FrameworkService.createFrameworkInstance(uuid, formData.title, formData.tags);
      setSnackbar({
        open: true,
        message: 'Framework created successfully',
        severity: 'success'
      });
      setOpenDialog(false);
      fetchInstances();
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || 'Failed to create framework',
        severity: 'error'
      });
    } finally {
      setIsCreating(false);
    }
  };

  const handleControlClick = (control, type) => {
    const mapping = getFrameworkMapping(type);
    setSelectedControl(mapping[control]);
  };

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case 'COMPLETED':
        return 'success';
      case 'IN_PROGRESS':
        return 'primary';
      case 'BLOCKED':
        return 'error';
      case 'NOT_STARTED':
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (loading && instances.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      width: '100%', 
      p: 3,
      maxWidth: 1800,
      margin: '0 auto'
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4
      }}>
        
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'left' }}>
          <Typography variant="h4" component="div" color="text.primary">
            Current Frameworks
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'right' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="ai-select-label">AI Model</InputLabel>
            <Select
              labelId="ai-select-label"
              id="ai-select"
              value={selectedAI}
              label="AI Model"
              onChange={(e) => setSelectedAI(e.target.value)}
            >
              {Object.entries(AI_TYPES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!userInfo.public_email && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpenDialog(true)}
              variant="contained"
              color="primary"
            >
              Create New Framework
            </Button>
          )} 
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      {/* Search Bar */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="framework-type-filter-label">Framework Type</InputLabel>
            <Select
              labelId="framework-type-filter-label"
              id="framework-type-filter"
              value={selectedFrameworkType || ''}
              label="Framework Type"
              onChange={(e) => setSelectedFrameworkType(e.target.value)}
            >
              <MenuItem value="">
                <em>All Types</em>
              </MenuItem>
              {FRAMEWORK_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Search Frameworks"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            startIcon={<FilterListIcon />}
            onClick={handleMenuOpen}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Columns
          </Button>
          <Button
            startIcon={<FilterList />}
            onClick={filterMenuOpen}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Filters
          </Button>
          <Button
            startIcon={<Download />} // Use an appropriate icon
            onClick={downloadCSV}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Export
          </Button>
          <Button
            startIcon={<RefreshIcon />}
            onClick={fetchInstances}
            variant="outlined"
            sx={{ ml: 2 }}
          >
            Refresh
          </Button>
        </Box>
      </Box>

      <Menu anchorEl={filterAnchorEl} open={Boolean(filterAnchorEl)} onClose={handleFilterMenuClose}>
        <Box sx={{ p: 2, width: 300 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Column</InputLabel>
            <Select
              value={filters.column}
              onChange={(e) => handleFilterChange('column', e.target.value)}
              label="Column"
            >
              {columns.map((col) => (
                <MenuItem key={col.id} value={col.id}>
                  {col.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Operator</InputLabel>
            <Select
              value={filters.operator}
              onChange={(e) => handleFilterChange('operator', e.target.value)}
              label="Operator"
            >
              {operators.map((op) => (
                <MenuItem key={op} value={op}>
                  {op}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Value"
            value={filters.value}
            onChange={(e) => handleFilterChange('value', e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={applyFilter} fullWidth>
            Apply Filter
          </Button>
        </Box>
      </Menu>
      
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {columns.map((column) => (
          <MenuItem key={column.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columnVisibility[column.id]}
                  onChange={() => toggleColumnVisibility(column.id)}
                />
              }
              label={column.label}
            />
          </MenuItem>
        ))}
      </Menu>

      <TableContainer 
        component={Paper}
        sx={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.palette.mode === 'dark' ? 'none' : undefined,
          border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.divider}` : undefined,
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="framework instances table">
          <TableHead>
            <TableRow>
              {columns.map(
                (column) =>
                  columnVisibility[column.id] && (
                    <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredInstances).map((instance) => (
              <React.Fragment key={instance.uuid}>
                <TableRow
                  sx={{ 
                    '&:hover': {
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? theme.palette.grey[800] 
                        : theme.palette.action.hover
                    },
                    '& > td': {
                      borderBottom: `1px solid ${theme.palette.divider}`
                    }
                  }}
                >
                  { columnVisibility['title'] && (
                    <TableCell>{instance.title}</TableCell>
                  )}
                  { columnVisibility['type'] && (
                    <TableCell>{instance.type}</TableCell>
                  )}
                  { columnVisibility['controls'] && (
                    <TableCell>
                    {instance.controls.map((control) => (
                      <Tooltip
                        key={control}
                        title={
                          <Box>
                            <div>Status: {instance.results.find(result => result.control === control)?.ai_result?.overallAssessment || 'Not Analyzed'}</div>
                            <div>Testing: {instance.automated?.[control] ? 'Automated' : 'Manual'}</div>
                            {instance.startDates?.[control] && (
                              <div>Start: {new Date(instance.startDates[control]).toLocaleDateString()}</div>
                            )}
                            {instance.dueDates?.[control] && (
                              <div>Due: {new Date(instance.dueDates[control]).toLocaleDateString()}</div>
                            )}
                          </Box>
                        }
                        placement="top"
                      >
                        <Chip
                          label={control}
                          size="small"
                          color={
                            instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'REJECT' ? 'error' : 
                            instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'WARNING' ? 'warning' : 
                            instance.results.find(result => result.control === control)?.ai_result?.overallAssessment === 'ACCEPT' ? 'success' : 'default'
                          } 
                          onClick={() => handleControlClick(control, instance.type)}
                          sx={{ 
                            mr: 1, 
                            mb: 1,
                            '& .MuiChip-label': {
                              color: theme.palette.mode === 'dark' ? theme.palette.text.primary : undefined
                            }
                          }}
                        />
                      </Tooltip>
                    ))}
                    </TableCell>
                  )}
                  { columnVisibility['testingStatus'] && (
                    <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title="Testing Status">
                        <Chip
                          size="small"
                          label={instance.testingStatus?.replace('_', ' ') || 'NOT STARTED'}
                          color={getStatusColor(instance.testingStatus)}
                        />
                      </Tooltip>
                    </Box>
                    </TableCell>
                  )}
                  { columnVisibility['reviewStatus'] && (
                    <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title="Review Status">
                        <Chip
                          size="small"
                          label={instance.reviewStatus?.replace('_', ' ') || 'NOT STARTED'}
                          color={getStatusColor(instance.reviewStatus)}
                        />
                      </Tooltip>
                    </Box>
                    </TableCell>
                  )}
                  { columnVisibility['tags'] && (
                    <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {instance.tags?.length > 0 && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          {instance.tags.map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              size="small"
                              variant="outlined"
                              sx={{ maxWidth: 100 }}
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                    </TableCell>
                  )}
                  { columnVisibility['created_by'] && (
                    <TableCell>{instance.created_by}</TableCell>
                  )}
                  { columnVisibility['created_at'] && (
                    <TableCell>{formatDate(instance.created_at)}</TableCell>
                  )}
                  { columnVisibility['actions'] && (
                    <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => setExpandedRow(expandedRow === instance.uuid ? null : instance.uuid)}
                      sx={{ color: theme.palette.text.primary }}
                    >
                      {expandedRow === instance.uuid ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {instance.created_by === userInfo?.email && !userInfo.public_email && (
                      <IconButton
                        size="small"
                        onClick={() => openShareDialog(instance)}
                        title="Share Framework"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        <ShareIcon />
                      </IconButton>
                    )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columns.filter(column => columnVisibility[column.id]).length} sx={{ p: 0 }}>
                    <Collapse in={expandedRow === instance.uuid}>
                      <Box sx={{ 
                        p: 3, 
                        backgroundColor: theme.palette.mode === 'dark' 
                          ? theme.palette.grey[900] 
                          : theme.palette.grey[50]
                      }}>
                        <Typography variant="h6" gutterBottom color="text.primary">
                          Framework Details
                        </Typography>
                        <Grid container spacing={2}>
                          {instance.controls.map((control) => {
                            const controlDetails = getFrameworkMapping(instance.type)[control];
                            return controlDetails ? (
                              <ControlCard
                                key={control}
                                control={control}
                                controlDetails={controlDetails}
                                instance={instance}
                                onAnalysisComplete={handleAnalysisComplete}
                                selectedAI={selectedAI}
                                // onUpdateDates={handleUpdateDates}
                                // onUpdateAutomated={handleUpdateAutomated}
                              />
                            ) : null;
                          })}
                        </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      {instances.length === 0 && !error && (
        <Box sx={{ 
          mt: 4, 
          textAlign: 'center',
          p: 4,
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.grey[900] 
            : theme.palette.grey[50],
          borderRadius: 1
        }}>
          <Typography color="text.secondary">
            No Frameworks found
          </Typography>
        </Box>
      )}

      <Dialog
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setShareEmail('');
          setSelectedFramework(null);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Share Framework
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              type="email"
              fullWidth
              value={shareEmail}
              onChange={(e) => setShareEmail(e.target.value)}
              disabled={isSharing}
              helperText="Enter the email address of the person you want to share with"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShareDialogOpen(false);
              setShareEmail('');
              setSelectedFramework(null);
            }}
            disabled={isSharing}
          >
            Cancel
          </Button>
          <Button
            onClick={handleShare}
            variant="contained"
            color="primary"
            disabled={!shareEmail || isSharing}
          >
            {isSharing ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Sharing...
              </Box>
            ) : (
              'Share'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Create New Framework
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Framework Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              fullWidth
              required
            />

            <TextField
              select
              label="Framework Type"
              value={formData.type}
              onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              fullWidth
            >
              {FRAMEWORK_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>

            <Autocomplete
              multiple
              freeSolo
              options={availableControls}
              getOptionLabel={(option) => typeof option === 'string' ? option : option.id}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Box>
                    <Typography variant="subtitle2">
                      {option.id} - {option.title}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Frequency: {option.frequency}
                    </Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Controls"
                  placeholder="Type or select controls"
                  helperText="Select from available controls or enter custom ones"
                />
              )}
              onChange={(_, newValue) => {
                const controls = newValue
                  .map(v => typeof v === 'string' ? v : v.id)
                  .join(', ');
                setFormData({ ...formData, controls });
              }}
            />

            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={formData.tags || []}
              onChange={(_, newValue) => setFormData({ ...formData, tags: newValue })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Add framework tags"
                />
              )}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Testing Status"
                  value={formData.testingStatus || 'NOT_STARTED'}
                  onChange={(e) => setFormData({ ...formData, testingStatus: e.target.value })}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Review Status"
                  value={formData.reviewStatus || 'NOT_STARTED'}
                  onChange={(e) => setFormData({ ...formData, reviewStatus: e.target.value })}
                >
                  {STATUS_OPTIONS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const date = e.target.value ? e.target.value : null;
                    setFormData({ ...formData, startDate: date });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Due Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const date = e.target.value ? e.target.value : null;
                    setFormData({ ...formData, dueDate: date });
                  }}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.automated || false}
                  onChange={(e) => setFormData({ ...formData, automated: e.target.checked })}
                />
              }
              label="Enable Automated Testing"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenDialog(false)}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateTemplate}
            variant="contained" 
            color="primary"
            disabled={isCreating || !formData.title || !formData.controls}
          >
            {isCreating ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                Creating...
              </Box>
            ) : (
              'Create'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!selectedControl}
        onClose={() => setSelectedControl(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          }
        }}
      >
        <DialogTitle sx={{ color: theme.palette.text.primary }}>
          Control Details
        </DialogTitle>
        <DialogContent>
          {selectedControl && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom color="text.primary">
                {selectedControl.title}
              </Typography>
              <Chip
                label={selectedControl.frequency}
                color={getControlFrequencyColor(selectedControl.frequency)}
                sx={{ mb: 2 }}
              />
              <Typography variant="body1" paragraph color="text.primary">
                {selectedControl.description}
              </Typography>
              <Typography variant="h6" gutterBottom color="text.primary">
                Test Steps
              </Typography>
              <Typography variant="body1" color="text.primary">
                {selectedControl.testSteps}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedControl(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            backgroundColor: theme.palette.mode === 'dark' 
              ? theme.palette.grey[900] 
              : undefined
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FrameworksPage;