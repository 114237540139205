import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Alert,
  Container,
  Stack,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AuthService from '../services/auth_service';

const ResetPasswordRequestForm = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [serverMessage, setServerMessage] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('light');
  
  const navigate = useNavigate();

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#ed7d31',
        dark: '#ed7d31',
      },
      background: {
        default: mode === 'dark' ? '#121212' : '#ffffff',
        paper: mode === 'dark' ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    setServerMessage({ type: '', message: '' });
    
    try {      
      await AuthService.resetPasswordRequest(formData.email);
      
      setServerMessage({
        type: 'success',
        message: 'If this email is registered, you will receive reset instructions shortly.'
      });
      
      setFormData({ email: '' });
    } catch (err) {
      setServerMessage({
        type: 'success',
        message: 'If this email is registered, you will receive reset instructions shortly.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            pt: 8,
            pb: 8,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'background.paper',
              position: 'relative',
            }}
          >
            <IconButton 
              sx={{ position: 'absolute', right: 16, top: 16 }}
              onClick={toggleColorMode}
              color="inherit"
            >
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <img 
                src={mode === 'dark' ? "logo.png" : "logo.png"}
                alt="Logo"
                style={{ maxWidth: '370px', height: 'auto' }}
              />
            </Box>

            <Typography component="h1" variant="h5" sx={{ mb: 3, color: 'text.primary' }}>
              Reset Password
            </Typography>

            {serverMessage.message && (
              <Alert 
                severity={serverMessage.type} 
                sx={{ 
                  width: '100%', 
                  mb: 3,
                  bgcolor: mode === 'dark' ? 'background.paper' : undefined
                }}
              >
                {serverMessage.message}
              </Alert>
            )}

            <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', color: 'text.primary' }}>
              Enter your email address and we'll send you instructions to reset your password.
            </Typography>

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Stack spacing={3}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  sx={{
                    mt: 2,
                    bgcolor: 'primary.main',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  }}
                >
                  {isLoading ? 'Sending Instructions...' : 'Send Reset Instructions'}
                </Button>

                <Button
                  fullWidth
                  variant="text"
                  onClick={() => navigate('/login')}
                  sx={{ 
                    mt: 1,
                    color: 'primary.main',
                  }}
                >
                  Back to Login
                </Button>
              </Stack>
            </form>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPasswordRequestForm;